/**
 * 参数处理
 * @param {*} params  参数
 */
export function tansParams(params) {
	let result = ''
	for (const propName of Object.keys(params)) {
		const value = params[propName];
		var part = encodeURIComponent(propName) + "=";
		if (value !== null && typeof (value) !== "undefined") {
			if (typeof value === 'object') {
				for (const key of Object.keys(value)) {
					if (value[key] !== null && typeof (value[key]) !== 'undefined') {
						let params = propName + '[' + key + ']';
						var subPart = encodeURIComponent(params) + "=";
						result += subPart + encodeURIComponent(value[key]) + "&";
					}
				}
			} else {
				result += part + encodeURIComponent(value) + "&";
			}
		}
	}
	return result
}


/**
 * 构造树型结构数据
 * @param {*} data 数据源
 * @param {*} id id字段 默认 'id'
 * @param {*} parentId 父节点字段 默认 'parentId'
 * @param {*} children 孩子节点字段 默认 'children'
 */
export function handleTree(data, id, parentId, children) {

	let config = {
		id: id || 'id',
		parentId: parentId || 'parentId',
		childrenList: children || 'children'
	};

	var childrenListMap = {};
	var nodeIds = {};
	var tree = [];

	for (let d of data) {
		let parentId = d[config.parentId];
		if (childrenListMap[parentId] == null) {
			childrenListMap[parentId] = [];
		}
		nodeIds[d[config.id]] = d;
		childrenListMap[parentId].push(d);
	}

	for (let d of data) {
		let parentId = d[config.parentId];
		if (nodeIds[parentId] == null) {
			tree.push(d);
		}
	}

	for (let t of tree) {
		adaptToChildrenList(t);
	}

	function adaptToChildrenList(o) {
		if (childrenListMap[o[config.id]] !== null) {
			o[config.childrenList] = childrenListMap[o[config.id]];
		}
		if (o[config.childrenList]) {
			for (let c of o[config.childrenList]) {
				adaptToChildrenList(c);
			}
		}
	}

	return tree;
}

export function parseTime(time, pattern) {
	if (arguments.length === 0 || !time) {
		return null
	}
	const format = pattern || '{y}-{m}-{d} {h}:{i}:{s}'
	let date
	if (typeof time === 'object') {
		date = time
	} else {
		if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
			time = parseInt(time)
		} else if (typeof time === 'string') {
			time = time.replace(new RegExp(/-/gm), '/').replace('T', ' ').replace(new RegExp(/\.[\d]{3}/gm), '');
		}
		if ((typeof time === 'number') && (time.toString().length === 10)) {
			time = time * 1000
		}
		date = new Date(time)
	}
	const formatObj = {
		y: date.getFullYear(),
		m: date.getMonth() + 1,
		d: date.getDate(),
		h: date.getHours(),
		i: date.getMinutes(),
		s: date.getSeconds(),
		a: date.getDay()
	}
	const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
		let value = formatObj[key]
		// Note: getDay() returns 0 on Sunday
		if (key === 'a') {
			return ['日', '一', '二', '三', '四', '五', '六'][value]
		}
		if (result.length > 0 && value < 10) {
			value = '0' + value
		}
		return value || 0
	})
	return time_str
}

export function download(url, params, filename) {
  downloadLoadingInstance = Loading.service({ text: "正在下载数据，请稍候", spinner: "el-icon-loading", background: "rgba(0, 0, 0, 0.7)", })
  return service.post(url, params, {
    transformRequest: [(params) => { return tansParams(params) }],
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    responseType: 'blob'
  }).then(async (data) => {
    const isLogin = await blobValidate(data);
    if (isLogin) {
      const blob = new Blob([data])
      saveAs(blob, filename)
    } else {
      const resText = await data.text();
      const rspObj = JSON.parse(resText);
      const errMsg = errorCode[rspObj.code] || rspObj.msg || errorCode['default']
      Message.error(errMsg);
    }
    downloadLoadingInstance.close();
  }).catch((r) => {
    console.error(r)
    Message.error('下载文件出现错误，请联系管理员！')
    downloadLoadingInstance.close();
  })
}

export function getTagList(arr) {
	let location = arr.map((el) => {
		if (el.type == "location") {
			return el.name;
		}
	});
	let category = arr.map((el) => {
		if (el.type == "category") {
			return el.name;
		}
	});
	let ageRange = arr.map((el) => {
		if (el.type == "ageRange") {
			return el.age;
		}
	});
	ageRange = ageRange.map((el) => {
		if (el) {
			return el.join(",");
		}
	});
	category = category.filter((n) => n);
	ageRange = ageRange.filter((n) => n);
	location = location.filter((n) => n);
	return {
		category,
		ageRange,
		location
	}
}

export function isObjEqual(obj) {
	let obj1 = {
		trivialName: obj.trivialName,
		trivialNameEn: obj.trivialNameEn,
		phylum: obj.phylum,
		phylumEn: obj.phylumEn,
		classA: obj.classA,
		classEn: obj.classEn,
		subclass: obj.subclass,
		subclassEn: obj.subclassEn,
		infraclass: obj.infraclass,
		infraclassEn: obj.infraclassEn,
		superorder: obj.superorder,
		superorderEn: obj.superorderEn,
		orderA: obj.orderA,
		orderEn: obj.orderEn,
		suborder: obj.suborder,
		suborderEn: obj.suborderEn,
		infraorder: obj.infraorder,
		infraorderEn: obj.infraorderEn,
		superfamily: obj.superfamily,
		superfamilyEn: obj.superfamilyEn,
		family: obj.family,
		familyEn: obj.familyEn,
		subfamily: obj.subfamily,
		subfamilyEn: obj.subfamilyEn,
		genus: obj.genus,
		genusEn: obj.genusEn,
		species: obj.species,
		speciesEn: obj.speciesEn,
	}

	let arr = Object.values(obj1)
	let flag = arr.some(el => {
		return el != '' || el != undefined
	})
	return !flag
}