import request from "../request";
// 公用搜索
function search (data) {
  return request({
    url: "/image/search",
    method: "post",
    data,
  });
}

// 综合搜索
// function comprehensiveSearch (data) {
//   return request({
//     url: "/image/comprehensiveSearch",
//     method: "post",
//     data,
//   });
// }

function recommendSearch (data) {
  return request({
    url: "/image/recommendSearch",
    method: "post",
    data,
  });
}
// 撤回审核
function updateAudit (data) {
  return request({
    url: "/image/audit/withdrawAudit",
    method: "put",
    data: data,
  });
}

// return api.post('/image/comprehensiveSearch ', qs.stringify(obj))
// 公共搜索
function comprehensiveSearch (data) {
  return request({
    url: "/image/comprehensiveSearch",
    method: "post",
    data: data,
  });
}

function referencesSearch (data) {
  return request({
    url: "/image/referencesSearch",
    method: "post",
    data: data,
  });
}

function GBDBSearch (data) {
  return request({
    url: "/image/GBDBSearchOr",
    method: "post",
    data: data,
  });
}


export default {
  search,
  updateAudit,
  comprehensiveSearch,
  referencesSearch,
  GBDBSearch,
  recommendSearch,
};
