export default {
  docSystem: '图库系统',
  search: '搜索',
  login: '登录',
  // personalInfo: '个人主页',
  personalInfo: '个人中心',

  // uploadArticl: '上传文章',
  uploadArticl: '上传图片',

  // mngSysPlat: '管理系统',
  mngSysPlat: '后台管理',

  // signout: '退出',
  signout: '退出登录',
  searchValHolder: '请输入内容',
  resetPass: '重置密码',
  newPassHolder: '请输入新密码',
  resetPassHolder: '请再次输入新密码',
  passResetSucc: '重置成功！即将跳转到登录页...',
  passResetErr: '重置失败',
  loginHolder: '请输入用户名、邮箱账号',
  passHolder: '请输入密码',
  rePassHOlder: '请再次输入密码',
  twoPassIsDifferent: '两次密码不相同',
  welcomeToLogin: '欢迎登录',
  userNameHolder: '请输入用户名、绑定的邮箱',
  userPassHolder: '请输入登录密码',
  registerNow: '立即注册',
  noUserCount: '没有账号？',
  forgetPass: '忘记密码',
  retrievePass: '找回密码',
  loginSucc: '登录成功！即将跳转到列表页...',
  loginErr: '登录失败',
  choseBackWay: '选择找回方式',
  inputCount: '输入账号',
  isOk: '完成',
  backWithUserName: '方式一：通过用户名找回',
  backWithEmail: '方式二：通过邮箱找回',
  inputCountPlease: '请输入账号',
  checkCount: '校验账号',
  lookForTips: '请到 {where} 邮箱中查收',
  preStep: '上一步',
  mailInfoSure: '邮箱信息确认',

  null: '空',
  yourMail: '您注册时邮箱是：',
  cancel: '取消',
  sure: '确定',
  countNotNull: '账号不可为空！',
  mailFormatIsErr: '邮箱格式不正确！',
  checkIsOk: '校验通过！',
  save: '保存',

  itsResason: '具体原因是',
  tips: '提示',
  mustInTips: '{name} 为必填字段',
  selectAuth: '选择作者',
  youMayFor: '你可能要查询的作者:',
  author: '作者',
  inputKeywordsHolder: '请输入关键词',
  firstAuth: '第一作者',
  telAuth: '通讯作者',
  noexitAuth: '作者不存在？您可尝试',
  addAuth: '添加作者',
  is: '是',
  no: '否',
  queryAuthInfoErr: '查询作者信息失败！！！具体原因是：',
  pleaseSelectAuth: '请选中作者',
  noSelect: '未选择',
  welcomeToRegister: '欢迎注册',
  inputUserNameHolder: '请输入用户名',
  inputBindEmail: '请输入绑定的邮箱',
  againSurePass: '再次确认密码',
  register: '注册',
  hadCount: '已有账号？',
  loginNow: '立即登录',
  inputEmailCount: '请输入邮箱账号',
  notRightEmailCount: '请输入正确的邮箱地址',
  passLenTips: '长度在 6 到 50 个字符',
  registerSucc: '注册成功！请到登录页登录',
  registerErr: '注册失败！！！具体原因是：',
  userIsNull: '用户名 不能为空',
  userLengthTips: '用户名只能由 3 ~ 10 位的数字字母下划线组成',
  citation: '引用',
  citationTips: '复制并粘贴一种已设定好的引用格式，或利用其中一个链接导入到文献管理软件中。',
  importLink: '导入链接',
  copySucc: '复制成功！',
  saveNums: '收藏数量',
  seeNums: '浏览数量',
  downNums: '下载数量',
  citationNums: '被引用数量',
  commonNums: '评论数量',
  linkArticle: '引文',

  aboutUs: '关于我们',
  compney: '中国科学院南京地质古生物研究所',
  addr: '南京市北京东路39号',
  connectUs: '联系我们',
  DrXuHonghe: '博士 徐红河',



  uploadModel: '上传3D模型',
  uploadPanorama: '上传全景图',
  uploadScan: "上传断层扫描",
  uploadxt:"形态数据录入",
  upresource:"资源上传",
  resource:"资源列表",
  searchTime: '时代搜索',
  searchCate: '分类搜索',
  searchArea: '地区搜索',

  myComment: '我的评论',
  myCollect: '我的收藏',
  myResource: '我的资源',


  auditStatus1: '被驳回',
  auditStatus2: '未审核',
  auditStatus3: '审核中',
  auditStatus4: '审核通过',

  lookComment:'查看评论',
  allComment:"全部评论",
  sendComment:"发送",
  reply:"回复",

  img:'图片',
  mode:"3D模型",
  panorama:"全景图",
  scan:'断层扫描',

  collect:"收藏",

  dcollect:'已收藏'




}