
import details from './details';
import common from './common';
import add from './add';


/**
 * @description 国际化 中文包
 */
export default {
  locale: 'zh-cn',
  common,
  details,
  add
}