import { render, staticRenderFns } from "./InfoDetail.vue?vue&type=template&id=53c2f392&scoped=true&"
import script from "./InfoDetail.vue?vue&type=script&lang=js&"
export * from "./InfoDetail.vue?vue&type=script&lang=js&"
import style0 from "./InfoDetail.vue?vue&type=style&index=0&id=53c2f392&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53c2f392",
  null
  
)

export default component.exports