import request from "../request";
// 登录
function login(data) {
  return request({
    url: "/login",
    method: "post",
    data,
  });
}

function register(data) {
  return request({
    // url: '/register',
    url: "/registerByEmail",
    headers: {
      isToken: false,
    },
    method: "post",
    data: data,
  });
}

// 获取详情
function getUserInfo(data) {
  return request({
    url: "/getInfo",
    method: "get",
    data,
  });
}
// 审核数量
function auditCount(type) {
  return request({
    url: "/center/auditCount/" + type,
    method: "get",
  });
}
// 修改头像
function avatar(data) {
  return request({
    url: "/system/user/profile/avatar",
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data,
  });
}

function updateUserPwd(oldPassword, newPassword) {
  const data = {
    oldPassword,
    newPassword,
  };
  return request({
    url: "/system/user/profile/updatePwd",
    method: "put",
    params: data,
  });
}
// 退出
function logout() {
  return request({
    url: "/logout",
    method: "post",
  });
}
// 收藏
function addFavorite(data) {
  return request({
    url: "/center/favorite",
    method: "post",
    data,
  });
}


function delFavorite(ids) {
  return request({
    url: "/center/favorite/" + ids,
    method: "DELETE",
  });
}

function getFavorite() {
  return request({
    url: "/center/favorite/listAll",
    method: "get",
  });
}

function ifFavorite(data) {
  return request({
    url: "/detail/favorite/ifFavorite",
    method: "post",
    data: data,
  });
}
function browseList(data) {
  return request({
    url: "/center/browse/list",
    method: "get",
    // data: data,
  });
}

export default {
  login,
  getUserInfo,
  logout,
  avatar,
  addFavorite,
  delFavorite,
  auditCount,
  getFavorite,
  ifFavorite,
  updateUserPwd,
  register,
  browseList,
};
