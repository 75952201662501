import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementUI from 'element-ui';
import vRegion from 'v-region';
import { isObjEqual, parseTime } from '@/utils/index';
import 'element-ui/lib/theme-chalk/index.css';
import I18n from 'vue-i18n';
import ZH from './locale/zh_CH';
import EN from './locale/en_US';
import request from './api/request';
import VueAMap from "vue-amap";


import Viewer from 'v-viewer';
import 'viewerjs/dist/viewer.css';

import echarts from 'echarts';
Vue.prototype.$echarts = echarts;

Vue.use(VueAMap);
VueAMap.initAMapApiLoader({
  key: '69c99c31ad464d82ebf75245957f6813',
  plugin: [                // 这里根据自己项目按需引入插件
    'Autocomplete',
    'PlaceSearch',
    'Scale',
    'OverView',
    'ToolBar',
    'MapType',
    'PolyEditor',
    'AMap.CircleEditor'
  ]
});
Vue.use(Viewer);
Viewer.setDefaults({
  inline: false, //启用inline模式
  button: false, //显示右上角关闭按钮
  navbar: false, //显示缩略图导航
  title: false, //显示当前图片的标题
  toolbar: true, //显示工具栏
  tooltip: true, //显示缩略百分比
  movable: true, //图片是否可移动
  zoomable: true, //图片是否可缩放
  rotatable: true, //图片是否可旋转
  scalable: true, //图片是否可反转
  transition: true, //使用css3过度
  fullscreen: false, //播放时是否全屏
  keyboard: true, //
  fullscreenButton: false, //右下角的全屏按钮
});
import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'swiper/dist/css/swiper.css';
Vue.use(VueAwesomeSwiper);

// 收藏评论组件
import comment from './components/comment/index.vue';
import favorite from './components/favorite/index.vue';
import GBDB from './views/detail/components/GBDB.vue';
import InfoDetail from './views/detail/components/InfoDetail.vue';

import references from './views/detail/components/references.vue';
Vue.component('favorite', favorite);
Vue.component('InfoDetail', InfoDetail);
Vue.component('comment', comment);
Vue.component('GBDB', GBDB);
Vue.component('references', references);

// import ELeMultiCascader from "ele-multi-cascader"
// // import "ele-multi-cascader/dist/cascader.css"
// Vue.use(ELeMultiCascader)

Vue.use(ElementUI);
Vue.use(vRegion);
Vue.use(I18n);



let lang = localStorage.getItem('language');
let defLang = EN.locale; // 默认英文
if (!lang) {
  lang = defLang;
  localStorage.setItem('language', lang);
}

export const i18n = new I18n({
  locale: lang,
  messages: {
    [ZH.locale]: {
      // ...zhLocale,
      ...ZH,
    },
    [EN.locale]: {
      // ...enLocale,
      ...EN,
    },
  },
});

Vue.prototype.$uploadUrl = process.env.VUE_APP_BASE_API;
Vue.config.productionTip = false;
Vue.prototype.$isObjEqual = isObjEqual;
Vue.prototype.parseTime = parseTime;
Vue.prototype.$http = request;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
