<template>
  <div>
    <div v-for="(item, index) in list" :key="index" class="witem" @click="openUrl(item.id)">
      {{ item.title }}
    </div>
    <div v-if="list.length == 0 && $i18n.locale != 'en-us'">暂无数据</div>
    <div v-else>no data</div>
  </div>
</template>

<script>
import commonApi from '@/api/common/index';
export default {
  props: ['name'],
  data() {
    return {
      list: [],
    };
  },
  created() { },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      const { data: res } = await commonApi.referencesSearch({ searchValue: this.name, pageSize: 5, pageNum: 1 });
      this.list = res.records;
    },
    openUrl(id) {
      window.open(`http://references.fossil-ontology.com/#/home/detail/${id}`);
    },
  },
};
</script>

<style scoped lang="scss">

</style>
