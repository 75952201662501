/**
 * 详情页
 */
export default {
  model: '3D模型',
  profile: '剖面',
  scanningDevice: '三维扫描设备',
  provider: "贡献者",
  dataSource: '数据来源',
  resolution: '解析度',
  dataVolume: '数据量',
  dataFormat: '数据格式',
  dataDescription: '数据描述',
  keyWords: '关键词',
  note: '其他',
  classInfoTitle: '古生物分类信息',
  fossilInfoTitle: '化石标本信息',
  geologicalTitle: '地质信息',
  geologicalTitle1: '地理信息',
  otherTitle: '其他详情信息',
  scannerType: '扫描类型',
  panoramaUrl: "全景图地址",

  phylum: '门',
  classA: '纲',
  subclass: '亚纲',
  infraclass: '次纲',
  superorder: '超目',
  orderA: '目',
  suborder: '亚目',
  infraorder: '次目',
  superfamily: '超科',

  family: '科',
  subfamily: '亚科',
  genus: '属',
  species: '种名',
  nomenclator: '命名人',
  defineTime: '种确定时间',
  revisedSpecies: '修订属种',

  doi: '文献DOI',
  reference: '发表文献',
  funding: '资助来源',
  typeSpecimen: '模式情况',
  prefix: '标本号前缀',
  specimentsId: '标本登记号',
  fieldNumber: '野外编号',
  plate: '图版编号',
  fig: '图片号',
  housedIn: '馆藏地',
  cabinet: '馆藏柜/厨',

  eon: '时代-宙',
  era: '时代-代',
  period: '时代-纪',
  epoch: '时代-世',
  age: '时代-期',
  time: '时间',
  ageFrom: '地质时代',
  ageTo: '末现年龄/Ma',
  ageMid: '平均年龄/Ma',
  section: '剖面',
  horizon: '层位',

  deviceNamaa: '拍摄设备名称与型号',
  locality: '产地',
  country: '国家',
  province: '省',
  city: '市',
  county: '县',
  village: '村',
  GPS: '经纬度',
  longitude: '经度',
  latitude: '纬度',
  attitude: '高程',


  description: '描述',
  notes: '备注',
  pictureName: '图文件名',
  deviceNameAndType: '设备名称与型号',
  input: '录入员',
  checkA: '校对员',



  txgs: "图像格式",
  dpi: "分辨率",
  Broadcategory: "大类",
  resourceName: '资源名称',
  author: '作者',
  materials: '材料',
  zip: '压缩包文件',
  resourcesLink: '资源链接',
  spot: '发现地',
  nomenclator: '命名人',
  myComment: '我的评论',
  myCollect: '我的收藏',
  myResource: '我的资源',

  auditStatus1: '被驳回',
  auditStatus2: '未审核',
  auditStatus3: '审核中',
  auditStatus4: '审核通过',

  informations: "图像信息",
  taxonomy: '古生物分类信息',
  specimen: '化石标本信息',
  lithostratigraphy: "地质信息",
  geography: "地理信息",
  otherInfo: '其他详情信息',
  ReferenceRecommend: "文献系统推荐",

  loading: '加载中',
  history: "浏览记录",
  update: '修改记录'




}