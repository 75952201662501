/**
 * 详情页
 */
export default {
  uploadArticle: '上传文章',
  uploadPdf: '上传PDF',
  delPdf: '删除PDF',
  dragHere: '将PDF拖到此处，或',
  clickUpload: '点击上传',
  uploadTips: '只能上传pdf文件，且不超过 100mb',
  articleTitle: '文章标题',
  titleHolder: '请输入标题',
  selectTypeHolder: '请选择文章类型',
  publishedYear: '出版年份',
  journalSite: '期刊网址',
  inputTitle: '请填写标题',
  selectAuthor: '请选择作者',
  selectPushYear: '请选择出版年份',
  itemMustNumTips: '{name} 必须为数字值',
  inputAbstract: '请填写摘要',
  inputKeywords: '请填写关键词',
  onlyPdf: '只支持PDF上传！',
  maxPdf: '上传PDF大小不能超过 10MB!',
  uploadErr: '上传失败',
  articleSaveOk: '文章保存成功！！！即将跳转到列表页...',
  articleSaveErr: '文章保存失败',
  getArticleErr: '获取文章类型失败',
  newAddAuthor: '新增作者',
  choseAvatar: '选择头像',
  orcid: 'orcid',
  name: '姓名',
  enName: '英文名',
  zhName: '中文名',
  researchAreas: '研究领域',
  country: '国家',
  workCompony: '工作单位',
  addr: '地址',
  email: 'email',
  emailFormatErr: 'email 格式不正确',
  authInfoSaveSucc: '作者信息保存成功！！！',
  authInfoSaveErr: '保存作者信息失败！！！具体原因是：',
  uploadAvatarSucc: '头像上传成功',
  avatarImgTips: '上传头像图片只能是图片格式',
  avatarMaxTips: '上传头像图片大小不能超过 10MB!',
  ages: "时代", // age(时代)
  geoSection: "地层剖面", //  geological section （地层剖面）
  addGeoSectionBtn: '+ 新增地层剖面',
  geoSectionName: '剖面名称',
  geoSectionImagName: '剖面图片名称',
  linkAddr: '链接地址',
  gps: " GPS",
  addGpsBtn: '+ 新增 GPS',
  longitude: "经度",
  latitude: "纬度",
  arroundOthers: "周边剖面推荐",
  geoFormation: "组", //  geological formation （组）
  geoFormationName: '组名',
  addGeoFormationBtn: '+ 新增组',
  fosCollection: "化石列表", //   fossil collection（化石列表）
  collectionName: '列表名称',
  fosCollectionName: '化石名称',
  fosCollectionNum: '化石编号',
  fosCollectionImg: '化石图片',
  addFosCollectionBtn: '+ 新增化石列表',
  dataSet: "数据集", //   data set（数据集）
  dataSetName: '数据名',
  addDataSetBtn: '+ 新增数据集',
  team: "团队", //   team(团队)
  addTeamBtn: "+ 新增团队",
  fundingName: '项目名称',
  fundingNum: '编号',
  addFundingBtn: '+ 新增项目',
  // ages: "时代",     //   funding(项目资助)

  issue: "提交",
  all: '全部',
  literature: "文献",
  mapdepot: '图库',
  form: '形态数据',
  specimen: '化石标本搜索',
  forms: '形态搜索',
  uploadPictures: "上传图片",
  clickAddPictures: "将文件拖到此处或点击上传图片",
  clickAddZip: "点击上传压缩包",

  upload3D: '上传3D模型',
  clickAdd3D: "点击上传3D模型",

  uploadQ: '上传全景图',
  clickAddQ: '点击上传全景图',
  findata: '开放数据',



}
