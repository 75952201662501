/**
 * 详情页
 */
export default {
  model: '3D Model',
  profile: 'Profile',
  scanningDevice: 'equipment',
  provider: 'Contributor',
  dataSource: 'DataSource',
  resolution: 'Resolution',
  dataVolume: 'Data Size',
  dataFormat: 'Data Format',
  dataDescription: 'Data Description',
  keyWords: 'Keyword',
  note: 'Other',
  classInfoTitle: 'Biotaxonomic Information',
  fossilInfoTitle: 'Fossil specimen Information',
  geologicalTitle: 'Geoinformatics',
  geologicalTitle1: 'Geographic Information',
  otherTitle: 'Other Details',
  panoramaUrl:'panoramaUrl',

  phylum: 'Phylum',
  classA: 'Class',
  subclass: 'Subclass',
  infraclass: 'Infraclass',
  superorder: 'Superorder',
  orderA: 'Order',
  suborder: 'Suborder',
  infraorder: 'Infraorder',
  superfamily: 'Superfamily',

  family: 'Family',
  subfamily: 'subfamily',
  genus: 'Genus',
  species: 'Species',
  nomenclator: 'nomenclator',
  defineTime: 'Define time',
  revisedSpecies: 'Revised Species',

  doi: 'DOI',
  reference: 'Reference',
  funding: 'Funding',
  typeSpecimen: 'Type Specimen',
  prefix: 'Prefix',
  specimentsId: 'Speciments id',
  fieldNumber: 'Field number',
  plate: 'Plate',
  fig: 'Fig',
  housedIn: 'Housed in',
  cabinet: 'Cabinet',

  eon: 'Eon',
  era: 'Era',
  period: 'Period',
  epoch: 'Epoch',
  age: 'Age',
  ageFrom: 'numerical age (Ma)',
  time:'Time',
  ageTo: 'Age to',
  ageMid: 'Age mid',
  section: 'Section',
  horizon: 'Horizon',

  locality: 'Locality',
  country: 'Country',
  province: 'Province',
  city: 'City',
  county: 'County',
  village: 'Village',
  longitude: 'Longitude',
  GPS:'GPS',
  latitude: 'Latitude',
  attitude: 'Attitude',
  scannerType:'scannerType',

  description: 'Description',
  notes: 'Notes',
  pictureName: 'Picture_Name',
  deviceNameAndType: 'Device name & type',
  input: 'Input',
  checkA: 'Check_A',

  deviceNamaa: 'Name and model of shooting equipment',

  txgs: 'Pic',
  dpi: 'Dpi',
  Broadcategory: 'Category ',

  resourceName: 'Resource Name',
  author: 'Author',
  materials: 'Materials',
  zip: 'Zip',
  resourcesLink: 'Links to resources',
  spot: 'The Spot',

  myComment: 'My comments',
  myCollect: 'My favorite ',
  myResource: 'My Resource',

  auditStatus1: 'Be rejected',
  auditStatus2: 'Unreviewed',
  auditStatus3: 'In review',
  auditStatus4: 'Approve',

  nomenclator: 'nomenclator',

  informations: 'Image informations',
  taxonomy: 'Biology taxonomy',
  specimen: 'Fossil specimen informations',
  lithostratigraphy: 'lithostratigraphy informations',
  geography: 'Geography informations',
  otherInfo: 'Other information',
  ReferenceRecommend: 'Reference recommend',
  loading: 'Loading',
  history: 'Browsing history',
  update: 'Update',
};
