const app = {
  state: {
    userInfo: null,
    token: {},
    pageList: [],
    searchTag: [],
    condition: {},
    formList: {},
    searchList: {},
    searchTitle: "",
    searchTotal: 0,
  },

  getters: {
    getSearchTotal: (state) => {
      return state.searchTotal;
    },
    getCondition: (state) => {
      return state.condition;
    },
    getUserInfo: (state) => {
      return state.userInfo;
    },
    getToken: (state) => {
      return state.userInfo;
    },
    getTitle: (state) => {
      return state.searchTitle;
    },
    getPageList: (state) => {
      return state.pageList;
    },
    getFormList: (state) => {
      return state.formList;
    },
    getSearchList: (state) => {
      return state.searchList;
    },
    getTag: (state) => {
      const obj = {};

      for (const item of state.searchTag) {
        obj[item.name + "," + item.type] = item;
      }
      state.searchTag = Object.values(obj);
      return state.searchTag;
    },
  },

  mutations: {
    SET_TOTAL (state, data) {
      state.searchTotal = data;
    },
    SET_TITLE (state, data) {
      state.searchTitle = data;
    },
    SET_USER (state, data) {
      state.userInfo = data;
    },
    SET_PAGE_LIST (state, data) {
      state.pageList = data;
    },
    ADD_PAGE_LIST (state, data) {
      // state.pageList.concat(...data)
      state.pageList = state.pageList.concat(...data);
    },
    SET_FORM_LIST (state, data) {
      state.formList = data;
    },
    SET_SEARCH_LIST (state, data) {
      state.searchList = data
    },
    SET_TOKEN (state, data) {
      state.token = data;
    },
    SET_TAG (state, data) {
      state.searchTag.push(data);
    },
    DEL_TAG (state, index) {
      state.searchTag.splice(index, 1);
    },
    SET_SEARCH (state, val) {
      let location = state.searchTag.map((el) => {
        if (el.type == "location") {
          return el.name;
        }
      });
      let category = state.searchTag.map((el) => {
        if (el.type == "category") {
          return el.name;
        }
      });
      let ageRange = state.searchTag.map((el) => {
        if (el.type == "ageRange") {
          return el.age;
        }
      });
      ageRange = ageRange.map((el) => {
        if (el) {
          return el.join(",");
        }
      });
      category = category.filter((n) => n);
      ageRange = ageRange.filter((n) => n);
      location = location.filter((n) => n);
      category = Array.from(new Set(category));
      ageRange = Array.from(new Set(ageRange));
      location = Array.from(new Set(location));
      let obj = {
        searchValue: val.searchVal,
        location: location || [],
        category: category || [],
        ageRange: ageRange || [],
        range: val.range,
        pageNum: val.from,
        pageSize: 20,
      };
      state.condition = obj;
      return obj;
    },
  },

  actions: {},
};

export default app;
