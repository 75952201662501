import Cookies from 'js-cookie'

const TokenKey = 'Admin-Token'




export function getUserInfo() {
  return JSON.parse(window.sessionStorage.getItem('ngs'))
}

export function setUserInfo(val) {
  return window.sessionStorage.setItem('ngs', JSON.stringify(val))
}

export function removeUserInfo() {
  return window.sessionStorage.removeItem('ngs')
}


export function setToken(val) {
  Cookies.set(TokenKey, val)
  return window.sessionStorage.setItem("ngstoken", JSON.stringify(val))
}

export function getToken() {
  return JSON.parse(window.sessionStorage.getItem("ngstoken"))
}

export function removeToken() {
  Cookies.remove(TokenKey)
  return window.sessionStorage.removeItem("ngstoken")
}