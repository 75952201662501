export default {
  docSystem: 'Map depot system',
  search: 'Search',
  login: 'Sign in',
  personalInfo: 'Home page',
  uploadArticl: 'Upload Image',
  mngSysPlat: 'Management system',
  signout: 'Sign out',
  searchValHolder: 'Please enter content',
  resetPass: 'Reset password',
  newPassHolder: 'Please enter a new password',
  resetPassHolder: 'Please enter the new password again',
  passResetSucc: 'Successfully reset! About to jump to the login page...',
  passResetErr: 'Reset failed',
  loginHolder: 'Please enter your username and email account',
  passHolder: 'Please enter password',
  rePassHOlder: 'Please enter the password again',
  twoPassIsDifferent: 'The two passwords are not the same',
  welcomeToLogin: 'Login please',
  userNameHolder: 'Please enter your username or bound email address',
  userPassHolder: 'Please enter your password',
  registerNow: 'Sign up now',
  noUserCount: 'No account?',
  forgetPass: 'Forget password',
  retrievePass: 'Retrieve password',
  loginSucc: 'Login successful! About to jump to the list page...',
  loginErr: 'Login failed',
  choseBackWay: 'Choose how to retrieve',
  inputCount: 'Enter the account number',
  isOk: 'OK',
  backWithUserName: 'Method 1: Retrieve by username',
  backWithEmail: 'Method 2: Retrieve via email',
  inputCountPlease: 'Please enter account',
  checkCount: 'Verify account',
  lookForTips: 'Please check in {where} email',
  preStep: 'Previous',
  mailInfoSure: 'Email confirmation',

  null: 'Null',
  yourMail: 'The email address when you register is: ',
  cancel: 'Cancel',
  sure: 'Sure',
  countNotNull: 'The account cannot be empty! ',
  mailFormatIsErr: 'E-mail format is incorrect! ',
  checkIsOk: 'Check passed! ',
  save: 'Save',

  itsResason: 'The specific reason is',
  tips: 'Tips',
  mustInTips: '{name} is a required field',
  selectAuth: 'Choose author',
  youMayFor: 'Authors you may want to query:',
  author: 'Author',
  inputKeywordsHolder: 'Please enter a keyword',
  firstAuth: 'First author',
  telAuth: 'Corresponding Author',
  noexitAuth: 'The author does not exist? You can try',
  addAuth: 'Add author',
  is: 'Yes',
  no: 'No',
  queryAuthInfoErr: 'Failed to query author information! ! ! The specific reasons are:',
  pleaseSelectAuth: 'Please select the author',
  noSelect: 'Not selected',
  welcomeToRegister: 'Welcome to register',
  inputUserNameHolder: 'Please enter user name',
  inputBindEmail: 'Please enter the bound email address',
  againSurePass: 'Confirm password again',
  register: 'Sign up',
  hadCount: 'Already have an account?',
  loginNow: 'Log in immediately',
  inputEmailCount: 'Please enter your email account',
  notRightEmailCount: 'Please input the correct email address',
  passLenTips: '6 to 50 characters in length',
  registerSucc: 'Registration success! Please log in to the login page',
  registerErr: 'registration failed! ! ! The specific reasons are:',
  userIsNull: 'Username can not be empty',
  userLengthTips: 'Username can only consist of 3-10 digits and underscores',
  citation: 'Cite',
  citationTips: 'Copy and paste a set citation format, or use one of the links to import it into the document management software.',
  importLink: 'Import link',
  copySucc: 'Copy successfully!',
  saveNums: 'Stars',
  seeNums: 'Views',
  downNums: 'Downloads',
  citationNums: 'Cite counts',
  commonNums: 'Number of comments',
  linkArticle: 'Citation',

  aboutUs: 'About us',
  compney: 'Nanjing Institute of Geology and Palaeontology, Chinese Academy of Sciences',
  addr: '39 Beijing East Road, Nanjing',
  connectUs: 'Contact us',
  DrXuHonghe: 'Dr. Xu Honghe',


  uploadModel: 'Upload 3d model',
  uploadPanorama: 'Upload panorama',
  uploadScan: "tomography",
  uploadxt:"Morphological data entry",
  upresource:"Resource upload",
  resource:"Resource",



  searchTime: 'Time',
  searchCate: 'Category',
  searchArea: 'Location',

  auditStatus1: 'Be Rejected',
  auditStatus2: 'Unreviewed ',
  auditStatus3: 'Under review',
  auditStatus4: 'Pass The Audit',
  lookComment: 'Comments',
  allComment: "All comments",
  sendComment:"Send",
  reply:"reply",

  img:'picture',
  mode:"3D model",
  panorama:"panorama",
  scan:'CT scan（CT）',

  collect:"Collection",
  dcollect:'have already collected'

}