<template>
  <!-- 图库 -->
  <div>
    <el-form ref="form" :model="detail" class="form" style="display: flex; flex-wrap: wrap">
      <div class="title" v-if="type == 1">
        <!-- <span>三维模型</span> -->
        <span>{{ $t('details.model') }}</span>
      </div>

      <!-- 3d模型独有信息 -->
      <div class="flex wrap jsb" v-if="type == 1">
        <el-form-item :label="$t('details.scanningDevice') + ':'" prop="scanningDevice">
          <span v-html="detail.scanningDevice" dd="请输入三维扫描设备"></span>
        </el-form-item>

        <el-form-item :label="$t('details.dataSource') + ':'" prop="dataSource">
          <span v-html="detail.dataSource" dd="请输入数据来源" v-if="$i18n.locale != 'en-us'"></span>
          <span v-html="detail.dataSourceEn" dd="请输入数据来源" v-else></span>
        </el-form-item>
        <el-form-item label="scanner TypeEn" prop="scannerTypeEn" v-if="$i18n.locale == 'en-us'">
          <span v-html="detail.scannerTypeEn" dd="请输入扫描类型-英文"></span>
        </el-form-item>
        <el-form-item :label="$t('details.resolution') + ':'" prop="resolution">
          <span v-html="detail.resolution" dd="请输入解析度"></span>
        </el-form-item>
        <el-form-item :label="$t('details.dataVolume') + ':'" prop="dataVolume">
          <span v-html="detail.dataVolume" dd="请输入数据量"></span>
        </el-form-item>
        <el-form-item :label="$t('details.dataFormat') + ':'" prop="dataFormat">
          <span v-html="detail.dataFormat" dd="请输入数据格式"></span>
        </el-form-item>
        <el-form-item :label="$t('details.keyWords') + ':'" prop="keyWords">
          <span v-html="detail.keyWords" dd="请输入关键词"></span>
        </el-form-item>
        <el-form-item :label="$t('details.note') + ':'" prop="note">
          <span v-html="detail.note" dd="请输入内容"></span>
        </el-form-item>
        <div style="width: 280px"></div>
      </div>
      <!-- 图片独有信息 -->

      <div class="title">
        <!-- <span>生物分类信息</span> -->
        <span>{{ $t('details.taxonomy') }}</span>
      </div>

      <div class="" v-if="$i18n.locale != 'en-us'">
        <el-form-item v-for="(item, index) in all" :label="$t(`details.${item.title}`) + ':'" prop="trivialName"
          :key="item.content" :style="{ 'marginLeft': index + 1 + '0px' }">
          <span v-html="item.content" dd="请输入大类"></span>
        </el-form-item>


        <div class="flex wrap" style="margin-top: 20px">
          <el-form-item :label="$t('details.nomenclator') + ':'" prop="nomenclatorEn">
            <span v-html="detail.nomenclatorEn" dd="请输入命名人-英文" v-if="$i18n.locale != 'en-us'"></span>
          </el-form-item>
          <el-form-item :label="$t('details.defineTime') + ':'" prop="defineTime">
            <span v-html="detail.defineTime" dd="确定时间"></span>
          </el-form-item>
          <el-form-item :label="$t('details.revisedSpecies') + ':'" prop="revisedSpecies">
            <span v-html="detail.revisedSpecies" dd="请输入修订属种" v-if="$i18n.locale != 'en-us'"></span>
            <span v-html="detail.revisedSpeciesEn" dd="请输入修订属种-英文" v-else></span>
          </el-form-item>
        </div>

        <div style="width: 220px"></div>
        <div style="width: 220px"></div>
      </div>

      <div class="" v-else>
        <el-form-item v-for="(item, index) in allEn" :label="$t(`details.${item.title}`) + ':'" prop="trivialName"
          :key="item.content" :style="{ 'marginLeft': index + 1 + '0px' }">
          <span v-html="item.content" dd="请输入大类"></span>
        </el-form-item>


        <div class="flex wrap" style="margin-top: 20px">
          <el-form-item :label="$t('details.nomenclator') + ':'" prop="nomenclatorEn">
            <span v-html="detail.nomenclatorEn" dd="请输入命名人-英文" v-if="$i18n.locale != 'en-us'"></span>
          </el-form-item>
          <el-form-item :label="$t('details.defineTime') + ':'" prop="defineTime">
            <span v-html="detail.defineTime" dd="确定时间"></span>
          </el-form-item>
          <el-form-item :label="$t('details.revisedSpecies') + ':'" prop="revisedSpecies">
            <span v-html="detail.revisedSpecies" dd="请输入修订属种" v-if="$i18n.locale != 'en-us'"></span>
            <span v-html="detail.revisedSpeciesEn" dd="请输入修订属种-英文" v-else></span>
          </el-form-item>
        </div>

        <div style="width: 220px"></div>
        <div style="width: 220px"></div>
      </div>

      <div class="title">
        <!-- <span>化石标本信息</span> -->
        <span>{{ $t('details.specimen') }}</span>
      </div>

      <div class="flex wrap jsb">
        <el-form-item :label="$t('details.reference') + ':'" prop="reference" style="width: 100%">
          <span v-html="detail.reference" dd="请输入发表文献" v-if="$i18n.locale != 'en-us'"></span>
          <span v-html="detail.referenceEn" dd="请输入发表文献-英文" v-else></span>
        </el-form-item>
        <el-form-item :label="$t('details.doi') + ':'" prop="doi" style="width: 100%">
          <span v-html="detail.doi" dd="请输入文献DOI"></span>
        </el-form-item>

        <el-form-item :label="$t('details.housedIn') + ':'" prop="housedIn" style="width: 50%">
          <span v-html="detail.housedIn" dd="请输入馆藏地"></span>
        </el-form-item>
        <el-form-item :label="$t('details.cabinet') + ':'" prop="cabinet" style="width: 50%">
          <span v-html="detail.cabinet" dd="请输入馆藏柜/厨"></span>
        </el-form-item>

        <el-form-item :label="$t('details.prefix') + ':'" prop="prefix">
          <span v-html="detail.prefix" dd="请输入标本号前缀"></span>
        </el-form-item>
        <el-form-item :label="$t('details.specimentsId') + ':'" prop="specimentsId">
          <span v-html="detail.specimentsId" dd="请输入标本登记号"></span>
        </el-form-item>
        <el-form-item :label="$t('details.fieldNumber') + ':'" prop="fieldNumber">
          <span v-html="detail.fieldNumber" dd="请输入野外编号"></span>
        </el-form-item>
        <el-form-item :label="$t('details.plate') + ':'" prop="plate">
          <span v-html="detail.plate" dd="请输入图版编号"></span>
        </el-form-item>
        <el-form-item :label="$t('details.fig') + ':'" prop="fig">
          <span v-html="detail.fig" dd="请输入图片号"></span>
        </el-form-item>
        <el-form-item :label="$t('details.funding') + ':'" prop="funding">
          <span v-html="detail.funding" dd="请输入资助来源"></span>
        </el-form-item>
        <el-form-item :label="$t('details.typeSpecimen') + ':'" prop="typeSpecimen">
          <span v-html="detail.typeSpecimen" dd="请输入模式情况"></span>
        </el-form-item>

        <div style="width: 220px"></div>
      </div>

      <div class="title">
        <!-- <span>地质信息</span> -->
        <span>{{ $t('details.lithostratigraphy') }}</span>
      </div>

      <div class="flex wrap jsb">
        <el-form-item :label="$t('details.eon') + ':'" prop="eon" style="width: 20%">
          <span v-html="detail.eon" dd="请输入时代-宙"></span>
        </el-form-item>
        <el-form-item :label="$t('details.era') + ':'" prop="era" style="width: 20%">
          <span v-html="detail.era" dd="请输入时代-代"></span>
        </el-form-item>
        <el-form-item :label="$t('details.period') + ':'" prop="period" style="width: 20%">
          <span v-html="detail.period" dd="请输入时代-纪"></span>
        </el-form-item>
        <el-form-item :label="$t('details.epoch') + ':'" prop="epoch" style="width: 20%">
          <span v-html="detail.epoch" dd="请输入时代-世"></span>
        </el-form-item>
        <el-form-item :label="$t('details.age') + ':'" prop="age" style="width: 40%">
          <span v-html="detail.age" dd="请输入时代-期" v-if="$i18n.locale != 'en-us'"></span>
          <span v-html="detail.ageEn" dd="请输入时代-英文" v-else></span>
        </el-form-item>

        <el-form-item :label="$t('details.ageFrom') + ':'" prop="ageFrom">
          <span v-html="detail.ageFrom" dd="请输入首现年龄/Ma"></span>
        </el-form-item>
        <el-form-item :label="$t('details.ageTo') + ':'" prop="ageTo">
          <span v-html="detail.ageTo" dd="请输入末现年龄/Ma"></span>
        </el-form-item>
        <el-form-item :label="$t('details.ageMid') + ':'" prop="ageMid">
          <span v-html="detail.ageMid" dd="请输入平均年龄/Ma"></span>
        </el-form-item>
        <el-form-item :label="$t('details.section') + ':'" prop="section" style="width: 50%">
          <span v-html="detail.section" dd="请输入剖面" v-if="$i18n.locale != 'en-us'"></span>
          <span v-html="detail.sectionEn" dd="请输入剖面-英文" v-else></span>
        </el-form-item>

        <el-form-item :label="$t('details.horizon') + ':'" prop="horizon" style="width: 50%">
          <span v-html="detail.horizon" dd="请输入层位" v-if="$i18n.locale != 'en-us'"></span>
          <span v-html="detail.horizonEn" dd="请输入层位-英文" v-else></span>
        </el-form-item>
        <div style="width: 220px"></div>
        <div style="width: 220px"></div>
      </div>

      <div class="title">
        <!-- <span>地理信息</span> -->
        <span>{{ $t('details.geography') }}</span>
      </div>

      <div class="flex wrap jsb">
        <el-form-item :label="$t('details.locality') + ':'" prop="locality" style="width: 100%">
          <span v-html="detail.locality" dd="请输入产地" v-if="$i18n.locale != 'en-us'"></span>
          <span v-html="detail.localityEn" dd="请输入产地-英文"></span>
        </el-form-item>

        <el-form-item :label="$t('details.country') + ':'" prop="country">
          <span v-html="detail.country" dd="请输入国家" v-if="$i18n.locale != 'en-us'"></span>
          <span v-html="detail.countryEn" dd="请输入国家-英文"></span>
        </el-form-item>

        <el-form-item :label="$t('details.province') + ':'" prop="province">
          <span v-html="detail.province" dd="请输入省" v-if="$i18n.locale != 'en-us'"></span>
          <span v-html="detail.provinceEn" dd="请输入省-英文"></span>
        </el-form-item>

        <el-form-item :label="$t('details.city') + ':'" prop="city">
          <span v-html="detail.city" dd="请输入市" v-if="$i18n.locale != 'en-us'"></span>
          <span v-html="detail.cityEn" dd="请输入市-英文" v-else></span>
        </el-form-item>

        <el-form-item :label="$t('details.county') + ':'" prop="county">
          <span v-html="detail.county" dd="请输入县" v-if="$i18n.locale != 'en-us'"></span>
          <span v-html="detail.countyEn" dd="请输入县-英文" v-else></span>
        </el-form-item>

        <el-form-item :label="$t('details.village') + ':'" prop="village">
          <span v-html="detail.village" dd="请输入村" v-if="$i18n.locale != 'en-us'"></span>
          <span v-html="detail.villageEn" dd="请输入村-英文" v-else></span>
        </el-form-item>

        <el-form-item :label="$t('details.longitude') + ':'" prop="longitude">
          <span v-html="detail.longitude" dd="请输入经度"></span>
        </el-form-item>
        <el-form-item :label="$t('details.latitude') + ':'" prop="latitude">
          <span v-html="detail.latitude" dd="请输入纬度"></span>
        </el-form-item>
        <el-form-item :label="$t('details.attitude') + ':'" prop="attitude">
          <span v-html="detail.attitude" dd="请输入高程"></span>
        </el-form-item>
        <div style="width: 206px"></div>
      </div>

      <div class="title">
        <!-- <span>其他详细信息</span> -->
        <span>{{ $t('details.otherInfo') }}</span>
      </div>
      <div class="flex wrap jsb">
        <div class="flex wrap jsb" v-if="type == 2">
          <el-form-item :label="$t('details.txgs') + ':'" prop="picFormat">
            <span v-html="detail.picFormat" dd="请输入图像格式"></span>
          </el-form-item>
        </div>
        <el-form-item :label="$t('details.pictureName') + ':'" prop="pictureName" style="width: 100%">
          <span v-html="detail.pictureName" dd="请输入图文件名"></span>
        </el-form-item>
        <el-form-item :label="$t('details.provider') + ':'" prop="provider">
          <span v-html="detail.provider" dd="请输入贡献者"></span>
        </el-form-item>
        <el-form-item :label="$t('details.input') + ':'" prop="input">
          <span v-html="detail.input" dd="请输入录入员"></span>
        </el-form-item>
        <el-form-item :label="$t('details.checkA') + ':'" prop="checkA">
          <span v-html="detail.checkA" dd="请输入校对员"></span>
        </el-form-item>
        <el-form-item :label="$t('details.deviceNameAndType') + ':'" prop="pictureName">
          <span v-html="detail.deviceNameAndType" dd="请输入设备名称与型号"></span>
        </el-form-item>
        <el-form-item :label="$t('details.notes') + ':'" prop="notes" style="width: 100%">
          <span v-html="detail.notes" dd="请输入内容" v-if="$i18n.locale != 'en-us'"></span>
          <span v-html="detail.notesEn" dd="请输入内容" v-else></span>
        </el-form-item>

        <el-form-item :label="$t('details.dpi') + ':'" prop="dpi" v-if="type == 2">
          <span v-html="detail.dpi" dd="请输入分辨率"></span>
        </el-form-item>
        <el-form-item :label="$t('details.keyWords') + ':'" prop="keyWords" v-if="type == 2">
          <span v-html="detail.keyWords" dd="请输入关键词"></span>
        </el-form-item>

        <el-form-item :label="$t('details.description') + ':'" prop="description" style="width: 100%" label-width="50px">
          <span v-html="detail.description" dd="请输入内容" v-if="$i18n.locale != 'en-us'"></span>
          <span v-html="detail.descriptionEn" dd="请输入描述-英文" v-else></span>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<script>
export default {
  props: ['detail', 'type'],
  data () {
    return {
      marginnum: 1,
      all: [],
      allEn: [],
    };
  },
  created () { },
  mounted () {
    this.getall()
  },
  computed: {

  },
  watch: {
    detail: {
      handler (val) {
        if (val.trivialName) {
          this.all.push({
            title: 'Broadcategory',
            content: val.trivialName
          })
        }
        if (val.trivialNameEn) {
          this.allEn.push({
            title: 'Broadcategory',
            content: val.trivialNameEn
          })
        }

        if (val.phylum) {
          this.all.push({
            title: 'phylum',
            content: val.phylum
          })
        }
        if (val.phylumEn) {
          this.allEn.push({
            title: 'phylum',
            content: val.phylumEn
          })
        }

        if (val.classA) {
          this.all.push({
            title: 'classA',
            content: val.classA
          })
        }
        if (val.classEn) {
          this.allEn.push({
            title: 'classA',
            content: val.classEn
          })
        }

        if (val.subclass) {
          this.all.push({
            title: 'subclass',
            content: val.subclass
          })
        }
        if (val.subclassEn) {
          this.allEn.push({
            title: 'subclass',
            content: val.subclassEn
          })
        }

        if (val.infraclass) {
          this.all.push({
            title: 'infraclass',
            content: val.infraclass
          })
        }
        if (val.infraclassEn) {
          this.allEn.push({
            title: 'infraclass',
            content: val.infraclassEn
          })
        }

        if (val.superorder) {
          this.all.push({
            title: 'superorder',
            content: val.superorder
          })
        }
        if (val.superorderEn) {
          this.allEn.push({
            title: 'superorder',
            content: val.superorderEn
          })
        }

        if (val.orderA) {
          this.all.push({
            title: 'orderA',
            content: val.orderA
          })
        }
        if (val.orderEn) {
          this.allEn.push({
            title: 'orderA',
            content: val.orderEn
          })
        }

        if (val.suborder) {
          this.all.push({
            title: 'suborder',
            content: val.suborder
          })
        }
        if (val.suborderEn) {
          this.allEn.push({
            title: 'suborder',
            content: val.suborderEn
          })
        }

        if (val.infraorder) {
          this.all.push({
            title: 'infraorder',
            content: val.infraorder
          })
        }
        if (val.infraorderEn) {
          this.allEn.push({
            title: 'infraorder',
            content: val.infraorderEn
          })
        }

        if (val.superfamily) {
          this.all.push({
            title: 'superfamily',
            content: val.superfamily
          })
        }
        if (val.superfamilyEn) {
          this.allEn.push({
            title: 'superfamily',
            content: val.superfamilyEn
          })
        }

        if (val.family) {
          this.all.push({
            title: 'family',
            content: val.family
          })
        }
        if (val.familyEn) {
          this.allEn.push({
            title: 'family',
            content: val.familyEn
          })
        }

        if (val.subfamily) {
          this.all.push({
            title: 'subfamily',
            content: val.subfamily
          })
        }
        if (val.subfamilyEn) {
          this.allEn.push({
            title: 'subfamily',
            content: val.subfamilyEn
          })
        }

        if (val.genus) {
          this.all.push({
            title: 'genus',
            content: val.genus
          })
        }
        if (val.genusEn) {
          this.allEn.push({
            title: 'genus',
            content: val.genusEn
          })
        }

        if (val.species) {
          this.all.push({
            title: 'species',
            content: val.species
          })
        }
        if (val.speciesEn) {
          this.allEn.push({
            title: 'species',
            content: val.speciesEn
          })
        }
      },

    }
  },
  methods: {
    getall () {

      this.marginnum = this.all.length
    }
  },
};
</script>

<style scoped lang="scss">
.title {
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  margin: 30px 0;
}

.form {
  .el-form-item {
    display: flex;
    font-size: 16px !important;
    margin-bottom: 0;
    width: 220px;

    ::v-deep .el-form-item__label {
      font-weight: 500;
    }

    ::v-deep .el-form-item__content {
      font-size: 12px !important;
      margin-left: 0 !important;
    }
  }

  ::v-deep input {
    border: none;
  }
}
</style>
