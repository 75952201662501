<template>
  <div>
    <!-- 标题 作者 出版社 出版时间 页码，期卷 -->
    <div v-for="(item, index) in list" :key="index" class="witem" @click="openUrl(item)">
      {{ item.ref_title }} {{ item.authorizer }} {{ item.ref_publisher }} {{ item.release_date }} {{
          item.ref_page_number_end
      }}
      {{ item.ref_page_number_begin }}
    </div>
    <div v-if="list.length == 0 && $i18n.locale != 'en-us'">暂无数据</div>
    <div v-if="list.length == 0 && $i18n.locale == 'en-us'">no data</div>
  </div>
</template>

<script>
import commonApi from '@/api/common/index';

export default {
  props: ['name', 'first', 'second'],
  data() {
    return {
      list: [],
    };
  },
  created() { },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      let obj = {
        refName: '', //文献名称
        sectionName: this.name, //剖面名称
        formationName: '', //组名称
        fossilFirstName: this.second.trim().split(/\s+/)[0], // 属名
        fossilSecondName: this.second.trim().split(/\s+/)[1], //种名 搜索种名要带着属名一块
        pageNum: 1,
        pageSize: 3,
      };
      const { data: res } = await commonApi.GBDBSearch(obj);
      this.list = res.result;
    },
    openUrl(val) {
      let str = '';
      if (val.section_basic_id) {
        str = `http://www.geobiodiversity.com/references/allresult/section?refId=${val.ref_id}&baseId=${val.section_basic_id}`;
      } else {
        str = `http://www.geobiodiversity.com/search/reference`;
      }
      window.open(str);
    },
  },
};
</script>

<style scoped lang="scss">

</style>
