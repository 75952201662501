<template>
  <div>
    <el-card class="remark">
      <div class="r-title">{{ $t("common.allComment") }}</div>
      <div class="d-flex alc">
        <el-input class="" type="textarea" :rows="3" placeholder="请输入评论内容" v-model="textarea"> </el-input>
        <div class="bg-primary" @click="sendComment">
          {{ $t("common.sendComment") }}
        </div>
      </div>

      <div class="record d-flex flex-row flex-wrap" v-for="(item, index) in commentList" :key="index">
        <img :src="$uploadUrl + item.fromUserAvatar" class="avatar" />

        <div class="text">
          <div class="name">
            <span style="color: #4fa0f8; margin-right: 20px"> {{ item.fromUserName }}</span>
            <span class="ml-2"> {{ item.createTime }}</span>
          </div>
          <div class="content">{{ item.content }}</div>
          <div v-if="item.imageCommentReplyList">
            <div class="child" v-for="(item1, index1) in item.imageCommentReplyList" :key="index1">
              <div class="d-flex flex-row">
                <img :src="$uploadUrl + item1.fromUserAvatar" class="avatar" />
                <div class="mt2">
                  <div class="name">
                    <span style="color: #4fa0f8" v-if="item1.replyType == 1"> {{ item1.fromUserName }} </span>
                    <span v-if="item1.replyType == 2" style="margin-right: 20px">
                      <span style="color: #4fa0f8"> {{ item1.fromUserName }} </span>
                      回复了
                      <span style="color: #4fa0f8"> {{ item.fromUserName }}</span>
                    </span>

                    <span class="ml-2">{{ item1.createTime }}</span>

                    <span class="content" style="margin-left: 40px; cursor: pointer" @click="hope(item1)">回复</span>
                  </div>
                  <div class="content">{{ item1.content }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="complico" @click="openChat(index)">
          <img src="../../assets/image/icon/pl.png" alt="" class="plico" />
          <span> {{ $t("common.reply") }}</span>
        </div>

        <div class="chat" v-if="item.chatFlag">
          <el-input v-model="sonTxt"></el-input>
          <div class="sonBtn" @click="sendSon(item)">
            <span> {{ $t("common.reply") }}</span>
          </div>
        </div>

        <div></div>
      </div>

      <div class="chat" v-if="SonChatFlag">
        回复{{ sendEndSon.fromUserName }}
        <el-input v-model="endTxt"></el-input>
        <div class="sonBtn" @click="replySon">
          {{ $t("common.reply") }}
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import commentApi from "@/api/comment/index";
import { getUserInfo } from "@/utils/auth";

export default {
  data () {
    return {
      textarea: "",
      commentList: "",
      sonTxt: "",
      endTxt: "",
      SonChatFlag: false,
      sendEndSon: {},
    };
  },
  props: {
    topicId: {
      type: String,
    },
    type: {
      type: Number,
    },
  },
  created () { },
  mounted () {
    this.init();
  },
  methods: {
    // 打开评论
    openChat (e) {
      this.SonChatFlag = false;
      let obj = JSON.parse(JSON.stringify(this.commentList[e]));
      if (obj.chatFlag) {
        obj.chatFlag = 0;
        this.$set(this.commentList, e, obj);
      } else {
        obj.chatFlag = 1;
        this.$set(this.commentList, e, obj);
      }
    },
    // 发送评论回复
    async sendSon (item) {
      const res = await commentApi.reply({ commentId: item.commentId, toReplyId: item.commentId, replyType: "1", content: this.sonTxt });
      if (res.code == 200) {
        this.$message({
          message: "发送成功",
          type: "success",
        });
        this.init();
        this.textarea = "";
      }
    },
    // 发送评论
    async sendComment () {
      const res = await commentApi.sendComment({ topicId: this.topicId, topicType: this.type, content: this.textarea });
      if (res.code == 200) {
        this.$message({
          message: "发送成功",
          type: "success",
        });
        this.textarea = "";
        this.init();
      } else if (res.code == 401 && !getUserInfo()) {
        MessageBox.confirm("登录状态已过期，您可以继续留在该页面，或者重新登录", "系统提示", {
          confirmButtonText: "重新登录",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            removeToken();
            removeUserInfo();
            location.href = "#/login";
          })
          .catch(() => { });
        return Promise.reject("无效的会话，或者会话已过期，请重新登录。");
      }
    },
    async init () {
      const { rows: res } = await commentApi.commentList({ topicId: this.topicId, topicType: this.type });
      this.commentList = res;
      this.commentList.map((el) => {
        el.chatFlag = 0;
      });
    },
    hope (e) {
      this.SonChatFlag = !this.SonChatFlag;
      this.sendEndSon = e;
    },
    async replySon () {
      const res = await commentApi.reply({
        commentId: this.sendEndSon.commentId,
        toReplyId: this.sendEndSon.replyId,
        replyType: "2",
        content: this.endTxt,
        toUid: this.sendEndSon.fromUid,
      });
      if (res.code == 200) {
        this.endTxt = "";
        this.$message({
          message: "发送成功",
          type: "success",
        });
        this.SonChatFlag = !this.SonChatFlag;
        this.sendEndSon = {};
        this.init();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.d-flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-wrap {
  flex-wrap: wrap;
}

.complico {
  cursor: pointer;
  position: absolute;
  top: 0px;
  right: 61px;
  align-items: center;
  display: flex;
}

.chat {
  width: 100%;
  display: flex;
  margin-top: 10px;
  align-items: center;

  .el-input {
    width: 630px;
    margin-left: 30px;
  }

  .sonBtn {
    margin-left: 30px;

    width: 90px;
    height: 31px;
    background: #4fa0f8;
    font-size: 14px;
    text-align: center;
    font-weight: 400;
    color: #ffffff;
    line-height: 31px;
  }
}

.remark {
  margin-top: 22px;
  margin-bottom: 22px;

  .el-textarea {
    width: 80%;
  }

  .r-title {
    font-size: 18px;
    font-weight: 600;
    color: #333333;
    line-height: 22px;
    margin-bottom: 20px;
  }

  .record {
    width: 100%;
    margin-bottom: 20px;
    margin-top: 20px;
    flex-wrap: wrap;
    position: relative;

    .avatar {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      margin-right: 20px;
      border: 1px solid black;
    }

    .item {
      break-inside: avoid;
      margin-bottom: 10px;
    }

    .item img {
      width: 100%;
      height: 100%;
    }

    .child {
      margin-top: 20px;

      .mt2 {
        margin-top: 10px;
      }
    }

    .text {
      width: 95%;

      .name {
        font-size: 14px;
        font-weight: 400;
        color: #aaaaaa;
        line-height: 20px;
      }

      .content {
        font-size: 14px;
        margin-top: 10px;
        word-break: break-all;
        font-weight: 400;
        color: #333333;
        line-height: 20px;
      }
    }
  }

  .bg-primary {
    opacity: 0.84;
    text-align: center;
    cursor: pointer;
    font-size: 14px;
    width: 65px;
    height: 43px;
    line-height: 43px;
    // border-radius: 20px;
    background: #4FA0F8;
    opacity: 0.84;
    font-weight: 400;
    color: #ffffff;
    // margin-left: 20px;
  }

  .text-coll {
    color: #898fa7;
  }
}
</style>
