import request from "../request";
// 评论列表
function commentList(query) {
    return request({
        url: "/detail/comment/list",
        method: "get",
        params: query
    })
}
// /发送评论
function sendComment(data) {
    return request({
        url: "/detail/comment",
        method: "post",
        data,
    })
}
// 回复
function reply(data) {
    return request({
        url: "/detail/reply",
        method: "post",
        data,
    })
}
// 回复列表 不用
// function replyList(query) {
//     return request({
//         url: "/image/reply/list",
//         method: "get",
//         params: query
//     })
// }
// /dev-api/image/comment/{commentIds}
function delComment(commentIds) {
    return request({
        url: `/detail/comment/${commentIds}`,
        method: "DELETE",
    })
}

export default {
    commentList,
    sendComment,
    reply,
    // replyList,
    delComment

}