

import details from './details';
import common from './common';
import add from './add';



/**
 * @description 国际化 英文包
 */
export default {
  locale: 'en-us',
  common,
  details,
  add
}