<template>
  <div class="favorite">
    <div class="hand end" v-if="!logoState" @click="$router.push('/login')"><i class="el-icon-star-off"></i>{{
      $t('common.collect')
    }}</div>
    <div class="end" v-else>
      <!-- <i class="el-icon-star-off"></i> -->
      <div @click="addFavorite" class="hand" v-if="!ifFavorite"><i class="el-icon-star-off"></i>{{ $t('common.collect')
      }}</div>
      <div @click="addFavorite" class="hand" v-else><i class="el-icon-star-on"></i>{{ $t('common.dcollect') }}</div>
    </div>

    <div class="ml-2 hand end" @click="scrollTo">
      <i class="el-icon-chat-dot-round"></i>
      {{ $t('common.lookComment') }}
    </div>

    <!-- <div class="ml-2 hand" @click="share" v-if="type == 1">
      <span v-if="$i18n.locale != 'en-us'">分享</span>
      <span v-else>Share</span>
    </div>

    <el-dialog :visible.sync="dialogVisible" class="Sbox">
      <div id="qrcode" ref="qrcode"></div>
    </el-dialog> -->
  </div>
</template>

<script>
import userApi from '@/api/user';
import { getUserInfo } from '@/utils/auth';
import QRCode from 'qrcodejs2';

export default {
  props: {
    topicId: {
      type: String,
    },
    type: {
      type: Number,
    },
  },
  data () {
    return { ifFavorite: '', logoState: false, dialogVisible: false, qrcode: '' };
  },
  created () { },
  mounted () {
    this.getLogin();
  },

  methods: {
    share () {
      this.dialogVisible = true;
      let arr = window.location.href.split('?')[1];
      let str = 'http://fossil-ontology.com/mobile?id=' + arr.split('=')[1];
      if (this.qrcode != '') {
        this.$refs.qrcode.innerHTML = '';
      }
      this.$nextTick(() => {
        this.qrcode = new QRCode('qrcode', {
          width: 250,
          height: 250, // 高度
          text: str, // 二维码内容
        });
      });
    },
    scrollTo () {
      this.$nextTick(() => {
        document.getElementById('target').scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
      });
    },
    getLogin () {
      if (getUserInfo()) {
        this.logoState = true;
        this.getIfFavorite();
      }
    },
    async getIfFavorite () {
      const { data: res } = await userApi.ifFavorite({ topicId: this.topicId, topicType: this.type });
      this.ifFavorite = res;
    },
    async addFavorite () {
      if (this.ifFavorite) {
        const res = await userApi.delFavorite(this.ifFavorite);

      } else {
        const res1 = await userApi.addFavorite({ topicId: this.topicId, topicType: this.type });
      }
      this.getIfFavorite();
    },
  },
};
</script>

<style scoped lang="scss">
.Sbox {
  ::v-deep .el-dialog__header {
    padding: 10px 20px 10px;
  }

  ::v-deep .el-dialog {
    width: 340px !important;
    height: 340px !important;
  }
}

.ml-2 {
  margin-left: 30px;
}

.favorite {
  width: 300px;
  display: flex;
  justify-content: center;
  font-size: 12px;

  i {
    font-size: 14px;
    margin-right: 5px;
  }

  .end {
    display: flex;
    align-items: end;
  }
}

#qrcode {
  margin: 0 auto;
  display: flex;
  justify-content: center;
}
</style>
