import Vue from 'vue';
import VueRouter from 'vue-router';
import { getToken } from '@/utils/auth';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: '/',
    redirect: '/home',
  },

  {
    path: '/home',
    name: 'Home',
    component: () => import('../views/home/index.vue'),
    redirect: '/home/newpage',
    children: [
      {
        path: 'list',
        name: 'list',
        component: () => import('../views/list/index.vue'),
      },
      {
        path: 'addImg',
        name: 'addImg',
        component: () => import('../views/add/addImg.vue'),
      },
      {
        path: 'add3D',
        name: 'add3D',
        component: () => import('../views/add/add3D.vue'),
      },
      {
        path: 'addPanorama',
        name: 'addPanorama',
        component: () => import('../views/add/addPanorama.vue'),
      },
      {
        path: 'addTomography',
        name: 'addTomography',
        component: () => import('../views/add/addTomography.vue'),
      },
      {
        path: 'imgInfo',
        name: 'imgInfo',
        component: () => import('../views/detail/imgInfo.vue'),
      },
      {
        path: '3dInfo',
        name: '3dInfo',
        component: () => import('../views/detail/3Dinfo2'),
      },
      {
        path: 'panoramaInfo',
        name: 'panoramaInfo',
        component: () => import('../views/detail/panoramaInfo2.vue'),
      },
      {
        path: 'tomographyInfo',
        name: 'tomographyInfo',
        component: () => import('../views/detail/tomographyInfo2.vue'),
      },

      {
        path: 'user',
        name: 'user',
        component: () => import('../views/user/index.vue'),
      },
      {
        path: 'dababase',
        name: 'dababase',
        component: () => import('../views/cate/index.vue'),
      },
      {
        path: 'all',
        name: 'all',
        component: () => import('../views/list/all.vue'),
      },
      {
        path: 'allList',
        name: 'allList',
        component: () => import('../views/list/allList.vue'),
      },
      {
        path: 'error',
        name: 'error',
        component: () => import('../views/login/error.vue'),
      },
      {
        path: 'success',
        name: 'success',
        component: () => import('../views/login/success.vue'),
      },
      {
        path: 'info2',
        name: 'info2',
        component: () => import('../views/detail/indoDetail2.vue'),
      },
      {
        path: 'newpage',
        name: 'newpage',
        component: () => import('../views/newpage/index.vue'),
      },
      {
        path: 'entering',
        name: 'entering',
        component: () => import('../views/from/entering.vue'),
      },
      {
        path: 'search',
        name: 'search',
        component: () => import('../views/from/search.vue'),
      },
      {
        path: 'form',
        name: 'form',
        component: () => import('../views/from/form.vue'),
      },
      {
        path: 'classfrom',
        name: 'classfrom',
        component: () => import('../views/from/index.vue'),
      },
       {
        path: 'event',
        name: 'event',
        component: () => import('../views/events/index.vue'),
      },
      {
        path: 'detail',
        name: 'detail',
        component: () => import('../views/events/detail.vue'),
      },
       {
        path: 'about',
        name: 'about',
        component: () => import('../views/about/index.vue'),
      },
      {
        path: 'aboutd',
        name: 'aboutd',
        component: () => import('../views/about/detail.vue'),
      },
       {
        path: 'upresource',
        name: 'upresource',
        component: () => import('../views/resource/upload.vue'),
      },
      {
        path: 'resource',
        name: 'resource',
        component: () => import('../views/resource/index.vue'),
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/index.vue'),
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/login/register.vue'),
  },
  {
    path: '/mobile',
    name: 'mobile',
    component: () => import('../views/detail/mobile.vue'),
  },
  

];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const whiteList = [
  '/login',
  '/',
  '/home/all',
  '/home/newpage',
  '/register',
  '/home/success',
  '/home/error',
  '/home/list',
  '/home/imgInfo',
  '/home/info2',
  '/mobile',
  '/home/3dInfo',
  '/home/panoramaInfo',
  '/home/dababase',
  '/home/entering',
  '/home/search',
  '/home/tomographyInfo',
  '/home/classfrom',
  '/home/all',
  '/home/allList',
  '/home/event',
  '/home/detail',
  '/home/about',
  '/home/aboutd',
];

router.beforeEach((to, from, next) => {
  document.documentElement.scrollTop = 0;
  // next()
  if (getToken()) {
    /* has token*/
    if (to.path === '/login') {
      next({
        path: '/',
      });
    } else {
      next();
    }
  } else {

    if (whiteList.indexOf(to.path) !== -1) {
      next();
    } else {
      next(`/login`);
    }
  }
});

export default router;
