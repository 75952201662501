/**
 * 详情页
 */
export default {
  uploadArticle: 'Upload article',
  uploadPdf: '上传PDF',
  delPdf: 'Upload PDF',
  dragHere: 'Drag the PDF here, or',
  clickUpload: 'Click upload',
  uploadTips: 'Only upload pdf files, and no more than 100mb',
  articleTitle: 'Article title',
  titleHolder: 'Please enter title',
  selectTypeHolder: 'Please select the article type',
  publishedYear: 'Year of publication',
  journalSite: 'Journal URL',
  inputTitle: 'Please fill in the title',
  selectAuthor: 'Please select the author',
  selectPushYear: 'Please select publication year',
  itemMustNumTips: '{name} must be a numeric value',
  inputAbstract: 'Please fill in the summary',
  inputKeywords: 'Please fill in keywords',
  onlyPdf: 'Only supports PDF upload!',
  maxPdf: 'Upload PDF size cannot exceed 10MB!',
  uploadErr: 'upload failed',
  articleSaveOk: 'The article is saved successfully! ! ! About to jump to the list page...',
  articleSaveErr: 'Failed to save article',
  getArticleErr: 'Failed to get article type',
  newAddAuthor: 'Add author',
  choseAvatar: 'Choose Avatar',
  orcid: 'orcid',
  name: 'Name',
  enName: 'English name',
  zhName: 'Chinese name',
  researchAreas: 'Research areas',

  workCompony: 'Unit',
  addr: 'Address',
  email: 'email',
  emailFormatErr: 'email format is incorrect',
  authInfoSaveSucc: 'Author information saved successfully! ! ! ',
  authInfoSaveErr: 'Failed to save author information! ! ! The specific reasons are: ',
  uploadAvatarSucc: 'Avatar upload successfully',
  avatarImgTips: 'Upload profile picture can only be in picture format',
  avatarMaxTips: 'The size of the uploaded profile picture cannot exceed 10MB!',
  ages: "Age", // age(时代)
  geoSection: "Geological section", //  geological section （地层剖面）
  addGeoSectionBtn: '+ New formation section',
  geoSectionName: 'Section name',
  geoSectionImagName: 'Profile picture name',
  linkAddr: 'Link address',
  gps: "GPS",
  addGpsBtn: '+ New GPS',
  latitude: "Latitude",
  longitude: "Longitude",
  arroundOthers: "Recommended surrounding profile",
  geoFormation: "Formation", //  Formation （组）
  geoFormationName: 'Formation',
  addGeoFormationBtn: '+ New Formation',
  fosCollection: "Fossil collection", //   fossil collection（化石列表）
  collectionName: 'Collection name',
  fosCollectionName: 'Fossil name',
  fosCollectionNum: 'Fossil number',
  fosCollectionImg: 'Fossil pictures',
  addFosCollectionBtn: '+ New Fossil collection',
  dataSet: "Data set", //   data set（数据集）
  dataSetName: 'Data set name',
  addDataSetBtn: '+ New Data set',
  team: "Team", //   team(团队)
  addTeamBtn: "+ New Team",
  fundingName: 'Funding name',
  fundingNum: 'Funding number',
  addFundingBtn: '+ New Funding',



  // 新增字段
  uploadPictures: 'upload pictures',
  clickAddPictures: 'Click Add image',

  basic: 'Basic',
  door: 'Door',
  class: 'Class',
  eye: 'Eye',
  family: 'Family',
  category: 'Category',
  seed: 'Seed',
  macrotaxonomy: 'Macrotaxonomy',
  namer: 'Namer',
  schedule: 'Schedule',

  PublishedSources: 'Published sources',
  SpecimenPrefix: 'Specimen prefix',
  SpecimenRegistrationNumber: 'Specimen Registration Number',
  TheFieldNumber: 'The field number',
  ChartNumber: 'Chart number',
  ByTheName: 'By the name',
  LocationOfSpecimen: 'Location of specimen',


  profile: 'Profile',
  position: 'Position',
  age: 'Age',
  ProductionPlace: 'ProductionPlace',
  country: 'Country',
  province: 'Province',
  city: 'City',
  county: 'County',
  village: 'Village',


  distribution: 'Distribution',
  MorphologicallySimilarSpecies: 'MorphologicallySimilarSpecies',
  describe: 'Describe',
  remark: 'Remark',
  issue: 'Issue',



  ageFil: 'Age screening',
  categoryFil: 'Category selection',



  all: 'All',
  mapdepot: 'Fossil Ontology',
  form: 'Morphology',
  literature: 'reference',
  findata: 'findata',

  specimen: 'specimen search',
  forms: 'form search',



  lookMore: 'read more',
  bioinformation: 'bioinformation',
  Physicalinformation: 'Physical information',
  geographicinformation: 'geographic information',
  geologicalinformation: 'geological information',

  issue: "Submit",
  all: 'All',

  uploadPictures: "Upload Pictures",
  clickAddPictures: "Click Upload Pictures",
  clickAddZip: "Click Upload Zip",



  upload3D: 'Upload Model',
  clickAdd3D: "Click Upload Model",


  uploadQ: 'Upload Panorama',
  clickAddQ: 'Click Upload Panoram',
}
